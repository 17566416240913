<template>
	<div class="app-container">		
		<el-button type="primary" :loading="buttonloading" class="mt-3 mb-3 float-right" v-if="permissionList.includes(permission.add)" v-b-modal.addRow icon="el-icon-plus">{{$t('button.add')}}</el-button>
		<el-button type="warning" :loading="buttonloading" class="mt-3 mr-3 mb-3 float-right" v-if="permissionList.includes(permission.preview)" @click="getEditHeader()" icon="el-icon-edit">{{$t('menu.front_edit_header')}}</el-button>
		<el-button type="success" :loading="buttonloading" class="mt-3 mr-2 mb-3 float-right" v-if="permissionList.includes(permission.preview)" @click="getPreviewRow()" icon="el-icon-view">{{$t('button.preview')}}</el-button>
		<el-table :data="tableData" v-loading="loading" style="width: 100%;" @expand-change="expandChange" @row-click="expandRow" class="mt-3" ref="tableTest" border fit highlight-current-row>
            <el-table-column v-for="title in ajaxTitles" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
                <template slot="header">
                    <p class="search-label">{{title.label}}</p>
                    <b-form-input :placeholder="title.label" @keyup.enter.native="initial" v-model="searchData[title.prop]" @change="saveData()"></b-form-input>
                </template>
            </el-table-column>
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-button type="primary" :loading="buttonloading" @click="getEditRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-edit">{{$t('button.edit')}}</el-button>
                    <el-button type="danger" :loading="buttonloading" @click="deleteRow(scope.row.id)" v-if="permissionList.includes(permission.delete)" icon="el-icon-delete">{{$t('button.delete')}}</el-button>
                </template>
            </el-table-column>
        </el-table>

		<pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="pagination"/>
		
		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>

        <b-modal id="addRow" :title="$t('menu.front_menu_add')" size="lg" hide-footer @hide="clearDataList()">
			<el-form @keyup.enter.native="addRow()" >
                <b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_code')}}</template>
					<b-form-input v-model="dataList.code"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_url')}}</template>
					<b-form-input v-model="dataList.url"></b-form-input>
				</b-form-group>
                <b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_parent_id')}}</template>
					<b-form-input v-model="dataList.parent_id"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_sort')}}</template>
					<el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_status')}}</template>
                    <el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
                <b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_is_parent')}}</template>
                    <el-switch v-model="dataList.is_parent" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_security')}}</template>
					<b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
				</b-form-group>

				<el-tabs class="mb-3">
                    <el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_name')}}</template>
                            <b-form-input v-model="dataList.name[item.id]" :disabled="loading"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_meta_title')}}</template>
                            <b-form-input v-model="dataList.meta_title[item.id]" :disabled="loading"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_meta_seo')}}</template>
                            <b-form-input v-model="dataList.meta_seo[item.id]" :disabled="loading"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_meta_description')}}</template>
                            <b-form-textarea v-model="dataList.meta_description[item.id]" :disabled="loading"  rows="4"></b-form-textarea>
                        </b-form-group>
                    </el-tab-pane>
                </el-tabs>
			</el-form>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('addRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="addRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>

		<b-modal id="editRow" :title="$t('menu.front_menu_edit')" size="lg" hide-footer @hide="clearDataList()">
			<el-form @keyup.enter.native="editRow()" >
                <b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_code')}}</template>
					<b-form-input v-model="dataList.code"></b-form-input>
                </b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_url')}}</template>
					<b-form-input v-model="dataList.url"></b-form-input>
                </b-form-group>
                <b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_parent_id')}}</template>
					<b-form-input v-model="dataList.parent_id"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_sort')}}</template>
					<el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_status')}}</template>
                    <el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
                <b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_is_parent')}}</template>
                    <el-switch v-model="dataList.is_parent" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_security')}}</template>
					<b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
				</b-form-group>
				
				<el-tabs class="mb-3">
                    <el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_name')}}</template>
                            <b-form-input v-model="dataList.name[item.id]" :disabled="loading"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_meta_title')}}</template>
                            <b-form-input v-model="dataList.meta_title[item.id]" :disabled="loading"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_meta_seo')}}</template>
                            <b-form-input v-model="dataList.meta_seo[item.id]" :disabled="loading"></b-form-input>
                        </b-form-group>
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_meta_description')}}</template>
                            <b-form-textarea v-model="dataList.meta_description[item.id]" :disabled="loading"  rows="4"></b-form-textarea>
                        </b-form-group>
                    </el-tab-pane>
				</el-tabs>
				
			</el-form>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('editRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="editRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>

		<b-modal id="editHeaderRow" :title="$t('menu.front_edit_header')" @hide="clearDataList()" size="lg" hide-footer no-enforce-focus>
			<el-form id="addForm" @submit.prevent="submit" enctype="multipart/form-data">
				<b-row>
					<b-col cols="12" md="6">
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_code')}}</template>
							<b-form-input v-model="headerDetail.code"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_background_color')}}</template>
							<b-form-input v-model="headerDetail.background_color"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_show_logo')}}</template>
							<el-switch v-model="headerDetail.show_logo" active-value="1" inactive-value="0"> </el-switch>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_logo_height')}}</template>
							<b-form-input v-model="headerDetail.logo_height"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_logo_width')}}</template>
							<b-form-input v-model="headerDetail.logo_width"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_image')}}</template>
							<el-popover placement="right" width="130" trigger="click" class="img-thumbnail" v-model="popover">
								<el-button type="primary" icon="el-icon-edit" @click="openImage();"></el-button>
								<el-button type="danger" icon="el-icon-delete" @click="initialImage();"></el-button>
								
								<el-image style="width: 100px; height: 100px" fit="fill" :src="headerDetail.img_url" slot="reference"></el-image>
							</el-popover>
						</b-form-group>
					</b-col>
					
					<b-col cols="12" md="6">
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_title')}}</template>
							<b-form-input v-model="headerDetail.title"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_font_size')}}</template>
							<b-form-input v-model="headerDetail.font_size"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_font_color')}}</template>
							<b-form-input v-model="headerDetail.color"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_line_height')}}</template>
							<b-form-input v-model="headerDetail.line_height"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_security')}}</template>
							<b-form-input v-model="headerDetail.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
			</el-form>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('editHeaderRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="editHeader()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>

		<b-modal id="previewRow" :title="$t('menu.front_menu_preview')" size="lg" >
			<el-tabs class="mb-3">
				<el-tab-pane style="height:200px;" v-for="language in languageList" :key="language.id" :item="language" :label="language.name">
					<b-navbar toggleable="lg" type="dark" v-bind:style="headerDetail.style">
						<b-navbar-brand href="#" v-if="headerDetail.show_logo == 1"><img src="../../assets/logo.png" v-bind:style="headerDetail.imageStyle"/></b-navbar-brand>
						<b-navbar-brand href="#" v-else><h3 v-bind:style="headerDetail.fontStyle">{{headerDetail.title}}</h3></b-navbar-brand>
						<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

						<b-collapse id="nav-collapse" is-nav>

						<b-navbar-nav class="ml-auto">
							<b-nav-item-dropdown v-for="item in menuList" :key="item.id" :item="item" :text="item.name[language.id]" right>	
							<b-dropdown-item v-for="index in item.sub_menu" :key="index.id" :href="index.url">{{index.name[language.id]}}</b-dropdown-item>
							</b-nav-item-dropdown>
						</b-navbar-nav>
						</b-collapse>
					</b-navbar>
				</el-tab-pane>
			</el-tabs>
        </b-modal>

		<imagemanager ref="imagemanager" :isShowDialog="openImageDialog" @dialogData="closeImageDialog" @getData="getImageDialog"/>
	</div>

</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import pagination from '@/components/pagination';
import backtotop from '@/components/backtotop';
import imagemanager from '@/views/image';

let searchForm = {
	pagination: 1,
	limit: 10,
	id: '',
	category:'',
	code:'',
	sort:'',
	status:'',
}
			
export default{
	components: { pagination, backtotop, imagemanager },
	inject:['preloader'],
	data(){
		return {
			loading: true,
			buttonloading: false,
			tableData: [],
			total: 0,
			errors: [],
			submitForm: {
				id:'',
				security:'',
			},
			postData: {
				data: '',
				language: '',
			},
			searchData:Object.assign({}, searchForm),
			listQuery: {
				page: 1,
				limit: 10
			},
			ajaxTitles:[{
                prop: "id",
                label: this.$t('admin_general.table_id'),
                width:'50'
            },{
                prop: "code",
                label: this.$t('admin_general.table_code'),
                width:'100'
			},{
                prop: "url",
                label: this.$t('admin_general.table_url'),
                width:'100'
			},{
                prop: "is_parent",
                label: this.$t('admin_general.table_is_parent'),
                width:'60'
			},{
                prop: "parent_id",
                label: this.$t('admin_general.table_parent_id'),
                width:'60'
			},{
                prop: "sort",
                label: this.$t('admin_general.table_sort'),
                width:'60'
			},{
                prop: "status",
                label: this.$t('admin_general.table_status'),
                width:'60'
			}],
			menuList: [],
			headerDetail: [],
			dataList:{
				code:'',
				url:'',
				sort:'',
                status:'',
                security:'',
                is_parent:'',
                parent_id:'',
                meta_description:[],
                meta_title:[],
                meta_seo:[],
                name:[]
			},
			permission:{
                ajaxTable:'NLNEI6',
                add:'2IPXOR',
                preview:'2IPXOR',
                DBadd:'5XIYKK',
                edit:'78DPZ7',
                DBedit:'RAIPIY',
                delete:'J8ZCBH',
                DBdelete:'CTYCN5'
            },
            permissionList:[],
            languageList:[],
			openImageDialog: false,
			popover: false,
			defaultImage: ''
		}
	}, methods: {
		initial(){
			if(this.permissionList.includes(this.permission.ajaxTable)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('front/menu/ajaxTable',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.tableData = data.datatable.data;
						self.total = parseInt(data.datatable.total_number);
						self.listQuery.page = parseInt(data.datatable.current_pagination);
						self.listQuery.limit = parseInt(data.datatable.limit);
						self.buttonloading = false;
						self.loading = false;
					}
				});
			}
		},clearDataList(){
			this.dataList.code = '';
			this.dataList.url = '';
			this.dataList.is_parent = '';
			this.dataList.parent_id = '';
			this.dataList.sort = 10;
			this.dataList.status = '';
			this.dataList.security = '';
			this.dataList.name = [];
			this.dataList.meta_description = [];
			this.dataList.meta_title = [];
			this.dataList.meta_seo = [];
		},addRow(){
			if(this.permissionList.includes(this.permission.DBadd)){
				this.preloader(true);
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var result = postMethod('front/menu/DBadd',this.postData);

				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						this.$bvModal.hide('addRow');
						this.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						this.$method.popupErrorMessage(this,text);
					}
					
					this.buttonloading = false;
					this.preloader(false);
				});
			}
		},getEditRow(id){
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var result = postMethod('front/menu/edit',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.dataList = data.thisDetail;
						this.$bvModal.show('editRow');
					}
					this.buttonloading = false;
				});
			}
		},editRow(){
			if(this.permissionList.includes(this.permission.DBedit)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var result = postMethod('front/menu/DBedit',this.postData);

				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						this.$bvModal.hide('editRow');
						this.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						this.$method.popupErrorMessage(this,text);
					}
					
					this.buttonloading = false;
					this.preloader(false);
				});
			}
		},deleteRow(id){
			if(this.permissionList.includes(this.permission.DBdelete)){
				this.buttonloading = true;
				var text = '';
				this.$prompt(this.$t('msg.msg_delete'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.submitForm.id = id;
					this.submitForm.security = value;
					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.submitForm));
					var result = postMethod('front/menu/DBdelete',this.postData);
					result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));
						if(value.valid){
							this.$message({
							type: 'success',
							message: data.returnMsg
							});
							this.buttonloading = false;
							this.initial();
						}else{
							this.errors = data.returnMsg;
							
							this.errors.forEach(function (value){
							text+= value+"<br/>";
							});
							
							this.$method.popupErrorMessage(this, text);
							this.buttonloading = false;
						}
					
					});
				}).catch(() => {
					this.buttonloading = false;          
				});
			}
		},getEditHeader(){
			if(this.permissionList.includes(this.permission.edit)){
				this.headerDetail = [];
				this.buttonloading = true;
				this.submitForm.code = 'header';
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var result = postMethod('front/menu/header',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.headerDetail = data.thisDetail;
						this.$bvModal.show('editHeaderRow');
					}
					this.buttonloading = false;
				});
			}
		},editHeader(){
			if(this.permissionList.includes(this.permission.DBedit)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.headerDetail));
				var text = '';
				var result = postMethod('front/menu/DBheader',this.postData);

				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						this.$bvModal.hide('editHeaderRow');
						this.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						this.$method.popupErrorMessage(this,text);
					}
					
					this.buttonloading = false;
					this.preloader(false);
				});
			}
		},getPreviewRow(){
			if(this.permissionList.includes(this.permission.edit)){
				this.headerDetail = [];
				this.buttonloading = true;
				this.submitForm.id = 1;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var result = postMethod('front/menu/preview',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.menuList = data.menuList;
						this.headerDetail = data.headerSetting;
						this.$bvModal.show('previewRow');
					}
					this.buttonloading = false;
				});
			}
		},pagination(){
			this.searchData.pagination = this.listQuery.page;
			this.searchData.limit = this.listQuery.limit;
			this.initial();
		},expandRow(row){
            this.$refs.tableTest.toggleRowExpansion(row);
        },expandChange(row,expandedRows){
            if(expandedRows.length>1){
                this.$refs.tableTest.toggleRowExpansion(expandedRows[0]);
            }
        },initialImage(){
			this.dataList.img_url = this.defaultImage;
			this.popover = false;
		},closeImageDialog(data) {
			this.openImageDialog = data;
		},openImage(){
			this.openImageDialog = true;
			this.$refs.imagemanager.onOpen(0); 
			this.popover = false;
		},getImageDialog(data){
			this.dataList.img_url = data.path;
			this.openImageDialog = false;
		},saveData(){
			searchForm = this.searchData;
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.postData.language = currentLang;
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
        this.languageList = JSON.parse(getLocalStorage('languageList'));
        this.initial();
	}
}
</script>